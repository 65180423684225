<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="row align-items-center ms-0 me-0 ms-lg-3 me-lg-3"
    style="padding: 29px 24px 14px 24px"
  >
    <div class="col-xxl mb-3 mb-xxl-0 heading-05 text-neutral-06">Wishlist</div>
    <div class="col">
      <div :class="{ 'multiselect-value-selected': filter.Category }">
        <VueMultiselect
          v-model="filter.Category"
          class="custom-multiselect"
          :options="categoriesList"
          label="Name"
          :close-on-select="true"
          track-by="Id"
          placeholder="Selectează categoria"
          select-label=""
          @open="GetWishListCategories()"
          @search-change="GetWishListCategories"
          @select="SelectPage(1)"
          @remove="SelectPage(1)"
        />
        <span
          class="btn-remove-multiselect"
          v-on:click="
            filter.Category = '';
            SelectPage(1);
          "
        >
        </span>
      </div>
    </div>
    <div class="col-md mt-3 mt-md-0">
      <div :class="{ 'multiselect-value-selected': filter.Subcategory }">
        <VueMultiselect
          :disabled="!filter.Category"
          v-model="filter.Subcategory"
          class="custom-multiselect"
          :options="filter.Category ? filter.Category.Subcategories : []"
          label="Name"
          :close-on-select="true"
          track-by="Id"
          placeholder="Selectează subcategoria"
          select-label=""
          @select="SelectPage(1)"
          @remove="SelectPage(1)"
        />
        <span
          class="btn-remove-multiselect"
          v-on:click="
            filter.Subcategory = '';
            SelectPage(1);
          "
        >
        </span>
      </div>
    </div>
    <div class="col-lg mt-3 mt-lg-0">
      <div class="input-group left">
        <span class="input-group-text">
          <img src="../../assets/images/icons/search-normal-20x20.svg" alt="..." />
        </span>
        <input
          v-model="filter.SearchQuery"
          @keyup.enter="$emit('get')"
          type="text"
          placeholder="Caută un produs..."
          class="form-control"
        />
      </div>
    </div>
  </div>
  <hr />
  <div class="row mt-4 ms-0 me-0">
    <div
      class="col-6 col-lg-4 col-xl-3 mb-3 pb-1 position-relative"
      v-for="(product, index) in products"
      :key="index"
      @mouseover="product.ShowCartButtons = true"
      @mouseleave="product.ShowCartButtons = false"
    >
      <DisplayProduct :product="product" @change="ChangeFavoriteStatus" />
      <add-to-cart-component
        :product="product"
        :user="currentUser"
        @prod-added-success-in-cart="ShowSuccessPopUp"
        @close-pop-up="CloseSuccessPopUp()"
      />
      <button
        type="button"
        class="delete-product-btn"
        @click="RemoveProductFromFavorite(product.Id)"
      >
        <img src="../../assets/images/icons/table-x-mark-20x20.svg" alt="..." />
      </button>
    </div>
  </div>
  <div v-if="products && products.length == 0" class="mt-5 mb-5">
    <not-found-component text="Nu există produse favorite" />
  </div>
  <pagination-component
    :numberOfPages="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    :functionName="SelectPage"
  ></pagination-component>

  <template v-if="showSuccessPopup && popUpComponent">
    <component
      :is="popUpComponent"
      @close-pop-up="CloseSuccessPopUp()"
      :showSuccessPopup="showSuccessPopup"
    />
  </template>
</template>

<script>
import { markRaw } from 'vue';
import PaginationComponent from '../General/PaginationComponent.vue';
import DisplayProduct from '../Product/DisplayProductComponent.vue';
import AddToCartComponent from '../General/AddToCartComponent.vue';
import NotFoundComponent from '../General/NotFoundComponent.vue';

import ProductAddedToCartPopUpComponent from '../Product/ProductAddedToCartPopUpComponent.vue';

export default {
  name: 'UserWishlist',
  emits: ['get', 'delete'],
  props: {
    products: Array,
    filter: Object,
    pagination: Object,
  },
  components: {
    PaginationComponent,
    DisplayProduct,
    AddToCartComponent,
    NotFoundComponent,
    ProductAddedToCartPopUpComponent,
  },
  data() {
    return {
      categoriesList: [],
      showSuccessPopup: false,
      popUpComponent: null,
    };
  },
  methods: {
    ShowSuccessPopUp() {
      this.popUpComponent = markRaw(ProductAddedToCartPopUpComponent);
      this.showSuccessPopup = true;
    },
    CloseSuccessPopUp() {
      this.showSuccessPopup = false;
      this.popUpComponent = null;
    },
    GetWishListCategories(searchText) {
      this.$axios
        .get(`/api/Category/getList?searchQuery=${searchText || ''}`)
        .then((response) => {
          this.categoriesList = response.data;
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr;
      this.$emit('get');
    },
    RemoveProductFromFavorite(id) {
      this.$swal
        .fire({
          title: 'Avertisment',
          text: 'Sigur doriți să ștergeți produsul de la favorite?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#dd2531',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Da',
          cancelButtonText: 'Renunță',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/User/removeFromFavorite/${id}`)
              .then(() => {
                this.$utils.ToastNotify('success', 'Produsul a fost șters de la favorite.');
                this.$emit('get');
              })
              .catch(() => {
                this.$utils.ToastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    async ChangeFavoriteStatus(product) {
      if (this.currentUser) {
        if (product.IsFavorit === false) {
          await this.$axios.post(`/api/User/addToFavorite/${product.Id}`);
          // eslint-disable-next-line no-param-reassign
          product.IsFavorit = true;
        } else {
          await this.$axios.delete(
            // eslint-disable-next-line comma-dangle
            `/api/User/removeFromFavorite/${product.Id}`
          );
          // eslint-disable-next-line no-param-reassign
          product.IsFavorit = false;
        }
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.currentUser;
  },
};
</script>
<style scoped>
.delete-product-btn {
  padding: 8px;
  border-radius: 8px !important;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  background-color: var(--neutral-2);
  position: absolute;
  top: 10px;
  left: 20px;
}

.delete-product-btn:hover {
  background-color: var(--neutral-1);
}
hr {
  color: var(--neutral-4) !important;
}
</style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="row align-items-center ms-0 me-0 ms-lg-3 me-lg-3"
    style="padding: 29px 24px 14px 24px"
  >
    <div class="col heading-05 text-neutral-06">Setări</div>
    <div class="col-auto">
      <button class="button btn-medium btn-success-solid" type="button" @click="SaveChanges()">
        Salvează modificările
      </button>
    </div>
  </div>
  <hr class="text-blacktint-02" />
  <div class="ps-4 pe-4 pt-2">
    <Form :validation-schema="schema" ref="userSettingsFormRef" v-slot="{ errors }">
      <!--name email phone-->
      <div class="text-button-01 text-neutral-05">Detalii generale</div>
      <div class="row mt-4">
        <div class="col-lg-4 mb-3 mb-lg-0" :class="{ 'invalid-input': errors.name }">
          <label for="name" class="text-neutral-07 text-button-02 mb-2">Nume</label>
          <Field
            v-model="user.UserName"
            id="name"
            name="name"
            placeholder="Nume complet"
            class="form-control"
            type="text"
          ></Field>
          <ErrorMessage class="error-message" name="name" />
        </div>
        <div class="col-sm mb-3 mb-sm-0" :class="{ 'invalid-input': errors.email }">
          <label for="email" class="text-neutral-07 text-button-02 mb-2">Email</label>
          <Field
            disabled
            v-model="user.Email"
            id="email"
            name="email"
            placeholder="Email"
            class="form-control"
            type="email"
          >
          </Field>
          <ErrorMessage class="error-message" name="email" />
        </div>
        <div class="col" :class="{ 'invalid-input': errors.phoneNumber }">
          <label for="phoneNumber" class="text-neutral-07 text-button-02 mb-2">Telefon</label>
          <Field
            v-model="user.PhoneNumber"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Telefon"
            class="form-control"
            type="tel"
          ></Field>
          <ErrorMessage class="error-message" name="phoneNumber" />
        </div>
      </div>
      <hr class="text-blacktint-01" />
      <!-- BILLING address city postal code county-->
      <div class="text-button-01 text-neutral-05">Adresă facturare</div>
      <div class="row mt-4">
        <div class="mb-3 mb-xl-0 col-md-6 col-xl-3" :class="{ 'invalid-input': errors.address }">
          <label for="billingAddress" class="text-neutral-07 text-button-02 mb-2">Adresă</label>
          <Field
            v-model="user.Billing.Address"
            id="billingAddress"
            name="address"
            placeholder="Nume complet"
            class="form-control"
            type="text"
          ></Field>
          <ErrorMessage class="error-message" name="address" />
        </div>
        <div class="mb-3 mb-xl-0 col-md-6 col-xl-3" :class="{ 'invalid-input': errors.state }">
          <label for="billingState" class="text-neutral-07 text-button-02 mb-2">Județ*</label>
          <Field
            v-model="user.Billing.State"
            name="billingState"
            id="billingState"
            placeholder="Județ.."
            class="form-control"
            type="text"
          />
          <!-- <Field v-model="user.Billing.State" name="state">
            <VueMultiselect
              v-model="user.Billing.State"
              id="billingState"
              label="Name"
              track-by="Id"
              placeholder="Județ.."
              open-direction="bottom"
              :options="states"
              :searchable="true"
              select-label=""
              :close-on-select="true"
              :max-height="600"
              @search-change="GetStates"
              @open="GetStates()"
              @remove="RemoveCity()"
              @select="RemoveCity()"
              class="custom-multiselect accentuate"
            >
            </VueMultiselect>
          </Field> -->
          <ErrorMessage class="error-message" name="billingState" />
        </div>
        <div class="mb-3 mb-xl-0 col-md-6 col-xl-3" :class="{ 'invalid-input': errors.city }">
          <label for="billingCity" class="text-neutral-07 text-button-02 mb-2">Oraș</label>
          <Field
            v-model="user.Billing.City"
            name="billingCity"
            id="billingCity"
            placeholder="Oraș.."
            class="form-control"
            type="text"
          />
          <!-- <Field v-model="user.Billing.City" name="city" :disabled="!user.Billing.State">
            <VueMultiselect
              ref="vueMultiselectCityRef"
              v-model="user.Billing.City"
              id="billingCity"
              label="DisplayName"
              track-by="Id"
              placeholder="Oraș.."
              open-direction="bottom"
              :options="cities"
              :searchable="true"
              select-label=""
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :max-height="600"
              @search-change="GetCitiesSearch"
              @open="GetCitiesSearch()"
              @select="SelectPostalCode()"
              :disabled="!user.Billing.State"
              class="custom-multiselect accentuate"
            >
            </VueMultiselect>
          </Field> -->
          <ErrorMessage class="error-message" name="billingCity" />
        </div>
        <div class="col-md-6 col-xl-3" :class="{ 'invalid-input': errors.billingPostalCode }">
          <label for="billingPostalCode" class="text-neutral-07 text-button-02 mb-2"
            >Cod poștal</label
          >
          <Field
            v-model="user.Billing.PostalCode"
            id="billingPostalCode"
            name="billingPostalCode"
            placeholder="Cod poștal..."
            class="form-control"
            type="text"
          ></Field>
          <ErrorMessage class="error-message" name="billingPostalCode" />
        </div>
      </div>
      <hr class="text-blacktint-01" />
      <!--DELIVERY address city postal code county-->
      <div class="row align-items-center">
        <div class="col-md mb-2 mb-md-0">
          <div class="text-button-01 text-neutral-05">Adresă livrare</div>
        </div>
        <div class="col-auto text-button-02 text-neutral-07">Păstrează adresa de facturare</div>
        <div class="col-auto">
          <div class="d-flex">
            <input
              class="switch switch-shadow"
              id="keep-billing-address-switch"
              type="checkbox"
              role="switch"
              v-model="user.KeepBillingAddress"
              @change="ChangeDeliveryAddressWithBilling()"
            />
            <label for="keep-billing-address-switch"></label>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="mb-3 mb-xl-0 col-md-6 col-xl-3" :class="{ 'invalid-input': errors.address }">
          <label for="deliveryAddress" class="text-neutral-07 text-button-02 mb-2">Adresă</label>
          <Field
            :disabled="this.user.KeepBillingAddress"
            v-model="user.Delivery.Address"
            id="deliveryAddress"
            name="deliveryAddress"
            placeholder="Nume complet"
            class="form-control"
            type="text"
          ></Field>
          <ErrorMessage class="error-message" name="deliveryAddress" />
        </div>
        <div class="mb-3 mb-xl-0 col-md-6 col-xl-3" :class="{ 'invalid-input': errors.state }">
          <label for="deliveryState" class="text-neutral-07 text-button-02 mb-2">Județ*</label>
          <Field
            :disabled="this.user.KeepBillingAddress"
            v-model="user.Delivery.State"
            name="deliveryState"
            id="deliveryState"
            placeholder="Județ.."
            class="form-control"
            type="text"
          />
          <!-- <Field v-model="user.Delivery.State" name="state">
            <VueMultiselect
              v-model="user.Delivery.State"
              id="deliveryState"
              label="Name"
              track-by="Id"
              placeholder="Județ.."
              open-direction="bottom"
              :options="states"
              :searchable="true"
              select-label=""
              :close-on-select="true"
              :max-height="600"
              @search-change="GetStates"
              @open="GetStates()"
              @remove="RemoveCity()"
              @select="RemoveCity()"
              class="custom-multiselect accentuate"
            >
            </VueMultiselect>
          </Field> -->
          <ErrorMessage class="error-message" name="state" />
        </div>
        <div class="mb-3 mb-xl-0 col-md-6 col-xl-3" :class="{ 'invalid-input': errors.city }">
          <label for="deliveryCity" class="text-neutral-07 text-button-02 mb-2">Oraș</label>
          <Field
            :disabled="this.user.KeepBillingAddress"
            v-model="user.Delivery.City"
            name="deliveryCity"
            id="deliveryCity"
            placeholder="Oraș.."
            class="form-control"
            type="text"
          />
          <!-- <Field v-model="user.Delivery.City" name="city" :disabled="!user.Delivery.State">
            <VueMultiselect
              ref="vueMultiselectCityRef"
              v-model="user.Delivery.City"
              id="deliveryCity"
              label="DisplayName"
              track-by="Id"
              placeholder="Oraș.."
              open-direction="bottom"
              :options="cities"
              :searchable="true"
              select-label=""
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :max-height="600"
              @search-change="GetCitiesSearch"
              @open="GetCitiesSearch()"
              @select="SelectPostalCode()"
              :disabled="!user.Delivery.State"
              class="custom-multiselect accentuate"
            >
            </VueMultiselect>
          </Field> -->
          <ErrorMessage class="error-message" name="deliveryCity" />
        </div>
        <div class="col-md-6 col-xl-3" :class="{ 'invalid-input': errors.deliveryPostalCode }">
          <label for="deliveryPostalCode" class="text-neutral-07 text-button-02 mb-2"
            >Cod poștal</label
          >
          <Field
            :disabled="this.user.KeepBillingAddress"
            v-model="user.Delivery.PostalCode"
            id="deliveryPostalCode"
            name="deliveryPostalCode"
            placeholder="Cod poștal..."
            class="form-control"
            type="text"
          ></Field>
          <ErrorMessage class="error-message" name="deliveryPostalCode" />
        </div>
      </div>
    </Form>
    <hr class="text-blacktint-01" />
    <div class="text-button-01 text-neutral-05">Notificări</div>
    <div class="row mt-4">
      <div class="col-auto text-button-02 text-neutral-07">
        Primește emailuri cu ofertele noastre
      </div>
      <div class="col-auto">
        <div class="d-flex">
          <input
            class="switch switch-shadow"
            id="enable-offerts-switch"
            type="checkbox"
            role="switch"
            v-model="user.AllowNewsLetter"
            @change="user.AllowNewsLetter != user.AllowNewsLetter"
          />
          <label for="enable-offerts-switch"></label>
        </div>
      </div>
    </div>
    <hr class="text-blacktint-01 d-md-none" />
    <div class="row mt-4 align-items-center mb-4">
      <div class="col d-none d-md-block"></div>
      <div class="col-auto text-button-02 text-danger-05 mb-3 mb-md-0">
        Ștergerea contul este o acțiune ireversibilă!
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="button btn-medium-wider btn-danger-solid"
          @click="$emit('delete', user.Id)"
        >
          Șterge contul
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'UserSettings',
  emits: ['edit', 'delete'],
  props: {
    user: Object,
  },
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  data() {
    return {
      cities: [],
      cityFilter: {
        PageSize: 42,
        PageNumber: 1,
        SearchQuery: '',
      },
      cityPagination: {},
      states: [],
      stateFilter: {
        PageSize: 42,
        PageNumber: 1,
        SearchQuery: '',
      },
      statePagination: {},
    };
  },
  methods: {
    ChangeDeliveryAddressWithBilling() {
      // eslint-disable-next-line no-unused-expressions, no-self-compare, eqeqeq
      this.user.KeepBillingAddress != this.user.KeepBillingAddress;
      const deliveryId = this.user.Delivery.Id;
      if (this.user.KeepBillingAddress) {
        // eslint-disable-next-line vue/no-mutating-props,
        this.user.Delivery = JSON.parse(JSON.stringify(this.user.Billing));
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.user.Delivery = {};
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.user.Delivery.Id = deliveryId;
    },
    async SaveChanges() {
      const formValidate = await this.$refs.userSettingsFormRef.validate();
      if (formValidate.valid) this.$emit('edit', this.user);
    },
    // SelectPostalCode() {
    //   // eslint-disable-next-line vue/no-mutating-props
    //   this.user.Location.PostalCode = this.user.Location.City.ZipCode;
    // },
    // RemoveCity() {
    //   this.$refs.vueMultiselectCityRef.removeLastElement();
    //   // eslint-disable-next-line vue/no-mutating-props
    //   this.user.Location.PostalCode = '';
    // },
    // GetCitiesSearch(searchQuery) {
    //   const searchParams = {
    //     PageNumber: this.cityFilter.PageNumber,
    //     PageSize: this.cityFilter.PageSize,
    //     ...(searchQuery ? { SearchQuery: searchQuery } : ''),
    //     ...(this.user.Billing.State && this.user.Billing.State.Id
    //       ? { CountyId: this.user.Billing.State.Id }
    //       : ''),
    //   };
    //   this.$axios
    //     .get(`api/County/getCities?${new URLSearchParams(searchParams)}`)
    //     .then((response) => {
    //       this.cities = response.data.Items;
    //       this.cityPagination = response.data.PageDetails;
    //     })
    //     .catch((error) => {
    //        this.$utils.ToastNotify('error', 'Something went wrong.');
    //     });
    // },
    // GetStates(searchQuery) {
    //   const searchParams = {
    //     PageNumber: this.stateFilter.PageNumber,
    //     PageSize: this.stateFilter.PageSize,
    //     ...(searchQuery ? { SearchQuery: searchQuery } : ''),
    //   };
    //   this.$axios
    //     .get(`api/County/getCounties?${new URLSearchParams(searchParams)}`)
    //     .then((response) => {
    //       this.states = response.data.Items;
    //       this.statePagination = response.data.PageDetails;
    //       // if (searchQuery && response.data.Items.length === 1) {
    //       //   // eslint-disable-next-line prefer-destructuring
    //       //   this.deliveryLocation.State = this.states[0];
    //       // }
    //     })
    //     .catch((error) => {
    //        this.$utils.ToastNotify('error', 'Something went wrong.');
    //     });
    // },
  },
  computed: {
    schema() {
      return yup.object({
        name: yup.string().required('Numele este obligatoriu.'),
        email: yup
          .string()
          .email('Acest e-mail nu este corect.')
          .required('Email-ul este obligatoriu.'),
        phoneNumber: yup
          .string()
          .required('Numărul de telefon este obligatoriu.')
          .trim()
          .min(10, 'Numărul de telefon trebuie să conțină 10 numere.')
          .test('validate-phone', 'Numărul de telefon nu este valid.', (phoneNumber) => {
            if (!phoneNumber) return true;
            return /^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/.test(phoneNumber);
          }),
        billingPostalCode: yup
          .string()
          .notRequired()
          .test('verify-postal-code', 'Codul poștal nu este valid.', (value) => {
            if (value) return /^(|.{5,6})$/.test(value);
            return true;
          }),
        deliveryPostalCode: yup
          .string()
          .notRequired()
          .test('verify-postal-code', 'Codul poștal nu este valid.', (value) => {
            if (value) return /^(|.{5,6})$/.test(value);
            return true;
          }),
        // address: yup
        //   .string()
        //   .notRequired()
        //   .test('verify-addres', 'Adresa este necesara', (value) => {
        //     if (!value) {
        //       return false;
        //     }
        //     return true;
        //   }),
        // country: yup
        //   .string()
        //   .notRequired()
        //   .test('verify-country', 'Tara este necesara', (value) => {
        //     if (!value) {
        //       return false;
        //     }
        //     return true;
        //   }),
        // state: yup
        //   .object()
        //   .notRequired()
        //   .test('verify-state', 'Judetul este necesar', (value) => {
        //     if (this.user.Location.State && this.user.Location.State.Name) {
        //       return true;
        //     }
        //     if (!value) {
        //       return false;
        //     }
        //     return true;
        //   }),
        // city: yup
        //   .object()
        //   .notRequired()
        //   .test('verify-city', 'Orasul este necesar', (value) => {
        //     if (this.user.Location.City && this.user.Location.City.Name) {
        //       return true;
        //     }
        //     if (!value) {
        //       return false;
        //     }
        //     return true;
        //   }),
        // postalCode: yup
        //   .string()
        //   .notRequired()
        //   .matches(/^(|.{5,6})$/, 'Codul postal nu este valid')
        //   .test('verify-postal-code', 'Codul postal nu este valid', (value) => {
        //     if (this.user.Location.State && this.user.Location.State.Name) {
        //       return /^\d+$/.test(value);
        //     }
        //     if (!value) {
        //       return false;
        //     }
        //     return /^\d+$/.test(value);
        //   }),
      });
    },
  },
};
</script>

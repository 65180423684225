<template>
  <div class="d-none d-lg-block">
    <div style="padding: 29px 24px 14px 24px" class="">
      <div class="row mb-3 align-items-center">
        <div class="col">
          <button
            type="button"
            @click="$emit('back')"
            class="button btn-small btn-neutral-outlined"
          >
           <img src="../../assets/images/icons/chevron-right-20x20-gray.svg" class="rotate-180degrees"/>
            <span class="ms-2 text-button-03 fw-semibold">Înapoi</span>
          </button>
        </div>
        <div class="col-auto" v-if="order.Payment == 'In magazin' && order.Status == 'New'">
          <button
            type="button"
            class="button btn-small btn-danger-solid"
            @click="CancelOrder(order.Id)"
          >
            Anulează comanda
          </button>
        </div>
      </div>

      <div class="row mt-2 pt-1">
        <div class="col">
          <div class="heading-06">
            <span class="text-neutral-06">Comanda: </span>
            <span class="text-neutral-08">#{{ order.Id }}</span>
          </div>
          <div class="row align-items-center mt-2 pt-1 text-button-02">
            <div class="col-auto text-neutral-05">
              <img
                src="../../assets/images/icons/gray-calendar-linear-20x20.svg"
                class="me-2"
                alt="..."
              />
              {{ order.Date }}
            </div>
            <div class="col-auto ps-1 pe-1">
              <svg
                width="4"
                height="5"
                viewBox="0 0 4 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="4" height="4" rx="2" fill="#688088" />
              </svg>
            </div>
            <div class="col-auto">
              <span class="text-neutral-05 me-2"> Status: </span>
              <span v-if="order.Status === 'Canceled'" class="text-danger-05 fw-semibold">
                {{ ShowTranslatedStatus(order.Status) }}
              </span>
              <span v-else class="text-info-06 fw-semibold">
                {{ ShowTranslatedStatus(order.Status) }}
              </span>
            </div>
            <div class="col-auto ps-1 pe-1">
              <svg
                width="4"
                height="5"
                viewBox="0 0 4 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="4" height="4" rx="2" fill="#688088" />
              </svg>
            </div>
            <div class="col-auto">
              <img
                src="../../assets/images/icons/gray-card-linear-20x20.svg"
                class="me-2"
                alt="..."
              />
              <span class="text-neutral-05"> Plata: </span>
              <span class="text-neutral-07 fw-semibold">
                {{ order.Payment }}
              </span>
            </div>
            <div class="col-12 mt-2 text-neutral-07">
              <img
                src="../../assets/images/icons/gray-location-linear-20x20.svg"
                class="me-2"
                alt="..."
              />
              {{ order.Address }}
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="text-uppercase text-neutral-05 total-fp-text text-center">Total</div>
          <div class="text-center">
            <span class="heading-02 text-neutral-08 me-2">{{ order.TotalPrice }}</span>
            <span class="heading-06 text-neutral-05">lei</span>
          </div>
          <div v-if="order.Coupon" class="d-flex justify-content-center align-items-center mt-2">
            <div class="coupon-border">
              <div class="d-flex justify-content-center align-items-center">
                <svg
                  class="me-1"
                  width="16"
                  height="16"
                  viewBox="0 1 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6067 9.79502C12.6067 10.655 13.3133 11.355 14.1733 11.355C14.1733 13.855 13.5467 14.4817 11.0467 14.4817H4.79334C2.29334 14.4817 1.66667 13.855 1.66667 11.355V11.0484C2.52667 11.0484 3.23334 10.3417 3.23334 9.48169C3.23334 8.62169 2.52667 7.91502 1.66667 7.91502V7.60836C1.67334 5.10836 2.29334 4.48169 4.79334 4.48169H11.04C13.54 4.48169 14.1667 5.10836 14.1667 7.60836V8.23502C13.3067 8.23502 12.6067 8.92836 12.6067 9.79502Z"
                    stroke="#95A6AC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.8074 4.48158H4.74739L6.70072 2.52825C8.29406 0.934919 9.09406 0.934919 10.6874 2.52825L11.0874 2.92825C10.6674 3.34825 10.5674 3.96825 10.8074 4.48158Z"
                    stroke="#95A6AC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.58594 4.48169L6.58594 14.4817"
                    stroke="#95A6AC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-dasharray="5 5"
                  />
                </svg>

                <span v-if="order.Coupon.Type" class="m-0 p-0 text-neutral-07 fw-semibold"
                  >{{ order.Coupon.Name }}: -{{ order.Coupon.Value }}%</span
                >
                <span v-else class="m-0 p-0 text-neutral-06 fw-semibold"
                  >{{ order.Coupon.Name }}: -{{ order.Coupon.Value }} RON</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="text-uppercase text-neutral-05 total-fp-text text-center">
            Puncte de fidelitate
          </div>
          <div class="text-center">
            <span class="heading-02 text-neutral-08 me-2">{{ order.FidelityPoints }}</span>
            <span class="heading-06 text-neutral-05">pts</span>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-3" />
    <div
      class="row align-items-center ms-4 mb-2 pb-2 me-4"
      :class="{ 'border-bottom': index < order.Products.Items.length - 1 }"
      v-for="(product, index) in order.Products.Items"
      :key="index"
    >
      <div class="col-auto heading-07 text-neutral-05 ps-0">{{ index + 1 }}</div>
      <div class="col-6">
        <div class="row product-box">
          <div class="col-auto">
            <img :src="$utils.ShowProductImage(product.ImgBase64)" alt="..." class="logo" />
          </div>
          <div class="col d-flex flex-column">
            <div class="heading-06 text-neutral-07 mb-2">
              {{ product.Name }}
            </div>
            <div
              v-if="product.Configurations.length > 0"
              class="row text-button-02 text-neutral-05 mb-2"
            >
              <div
                v-for="(configuration, index) in product.Configurations"
                :key="index"
                class="col-auto"
              >
                <div class="row alig-items-center">
                  <div class="col-auto">
                    {{ configuration.Name }}
                  </div>
                  <div class="col-auto ps-1 pe-1" v-if="index < product.Configurations.length - 1">
                    <svg
                      width="4"
                      height="5"
                      viewBox="0 0 4 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="0.5" width="4" height="4" rx="2" fill="#688088" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="label-02 text-neutral-05 me-2">Cantitate:</span>
              <span class="text-button-02 text-neutral-05">x{{ product.Quantity }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="text-uppercase text-neutral-05 total-fp-text text-center">Total</div>
        <div class="text-center">
          <span class="heading-06 text-neutral-08 me-2">{{ product.Price }}</span>
          <span class="heading-08 text-neutral-05">lei</span>
        </div>
      </div>
      <div class="col">
        <div class="text-uppercase text-neutral-05 total-fp-text text-center">
          Puncte de fidelitate
        </div>
        <div class="text-center">
          <span class="heading-06 text-neutral-08 me-2">{{ product.FidelityPoints }}</span>
          <span class="heading-08 text-neutral-05">pts</span>
        </div>
      </div>
    </div>

  </div>
  <!--mobile-->
  <div class="d-lg-none p-3">
    <div class="row mb-3 align-items-center">
      <div class="col">
        <button type="button" @click="$emit('back')" class="button btn-small btn-neutral-outlined">
          <img src="../../assets/images/icons/chevron-right-20x20-gray.svg" class="rotate-180degrees"/>
          <span class="ms-2 text-button-03 fw-semibold">Înapoi</span>
        </button>
      </div>
      <div class="col-auto" v-if="order.Payment == 'In magazin' && order.Status == 'New'">
        <button
          type="button"
          class="button btn-small btn-danger-solid"
          @click="CancelOrder(order.Id)"
        >
          Anulează comanda
        </button>
      </div>
    </div>
    <div class="heading-06 mt-3">
      <span class="text-neutral-06">Comanda: </span>
      <span class="text-neutral-08">#{{ order.Id }}</span>
    </div>
    <div class="row mt-3 align-items-center justify-content-between">
      <div class="col text-neutral-05">
        <div class="border rounded-2 p-2">
          <img
            src="../../assets/images/icons/gray-calendar-linear-20x20.svg"
            class="mb-1"
            alt="..."
          />
          <br />
          {{ order.Date }}
        </div>
      </div>
      <div class="col">
        <div class="border rounded-2 p-2">
          <div class="text-neutral-05 mb-1">Status:</div>
          <div v-if="order.Status === 'Canceled'" class="text-danger-05 fw-semibold text-button-02">
            {{ ShowTranslatedStatus(order.Status) }}
          </div>
          <div v-else class="text-info-06 fw-semibold text-button-02">
            {{ ShowTranslatedStatus(order.Status) }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-2 mt-md-0">
        <div class="border rounded-2 p-2">
          <img src="../../assets/images/icons/gray-card-linear-20x20.svg" class="mb-1" alt="..." />
          <div>
            <span class="text-neutral-05 mb-1"> Plata: </span>
            <span class="text-neutral-07 fw-semibold">
              {{ order.Payment }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="border rounded-2 p-2">
          <img
            src="../../assets/images/icons/gray-location-linear-20x20.svg"
            class="mb-1"
            alt="..."
          />
          <div class="text-neutral-07 fw-semibold">
            {{ order.Address }}
          </div>
        </div>
      </div>
    </div>
    <hr class="text-blacktint-02" />
    <div class="row align-items-center justify-content-evenly">
      <div class="col-auto">
        <div class="text-uppercase text-neutral-05 total-fp-text text-center">
          Total <br />
          plată
        </div>
        <div class="text-center heading-06 mt-2">
          <span class="text-neutral-08 me-2">{{ order.TotalPrice }}</span>
          <span class="text-neutral-05">lei</span>
        </div>
      </div>
      <div class="col-auto">
        <div class="text-uppercase text-neutral-05 total-fp-text text-center">
          Puncte de <br />
          fidelitate
        </div>
        <div class="text-center heading-06 mt-2">
          <span class="text-neutral-08 me-2">{{ order.FidelityPoints }}</span>
          <span class="text-neutral-05">pts</span>
        </div>
      </div>
      <div class="col-12 mt-3" v-if="order.Coupon">
        <div class="d-flex justify-content-center align-items-center">
          <div class="coupon-border">
            <div class="d-flex justify-content-center align-items-center">
              <svg
                class="me-1"
                width="16"
                height="16"
                viewBox="0 1 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6067 9.79502C12.6067 10.655 13.3133 11.355 14.1733 11.355C14.1733 13.855 13.5467 14.4817 11.0467 14.4817H4.79334C2.29334 14.4817 1.66667 13.855 1.66667 11.355V11.0484C2.52667 11.0484 3.23334 10.3417 3.23334 9.48169C3.23334 8.62169 2.52667 7.91502 1.66667 7.91502V7.60836C1.67334 5.10836 2.29334 4.48169 4.79334 4.48169H11.04C13.54 4.48169 14.1667 5.10836 14.1667 7.60836V8.23502C13.3067 8.23502 12.6067 8.92836 12.6067 9.79502Z"
                  stroke="#95A6AC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.8074 4.48158H4.74739L6.70072 2.52825C8.29406 0.934919 9.09406 0.934919 10.6874 2.52825L11.0874 2.92825C10.6674 3.34825 10.5674 3.96825 10.8074 4.48158Z"
                  stroke="#95A6AC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.58594 4.48169L6.58594 14.4817"
                  stroke="#95A6AC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-dasharray="5 5"
                />
              </svg>

              <span v-if="order.Coupon.Type" class="m-0 p-0 text-neutral-07 fw-semibold"
                >{{ order.Coupon.Name }}: -{{ order.Coupon.Value }}%</span
              >
              <span v-else class="m-0 p-0 text-neutral-06 fw-semibold"
                >{{ order.Coupon.Name }}: -{{ order.Coupon.Value }} RON</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="text-blacktint-02" />
    <div class="row">
      <div
        class="col-md-6 col-lg-4 mb-3"
        v-for="(product, index) in order.Products.Items"
        :key="index"
      >
        <div class="order card">
          <div class="card-body">
            <div class="row align-items-center mb-3">
              <div class="col-auto pe-0">
                <img :src="$utils.ShowProductImage(product.ImgBase64)" alt="..." class="logo" />
              </div>
              <div class="col">
                <div class="text-button-01 text-neutral-07">
                  {{ product.Name }}
                </div>
              </div>
            </div>
            <div
              class="row align-items-center justify-content-between mb-2 paragraph-03"
              v-for="(config, configIndex) in product.Configurations"
              :key="configIndex"
            >
              <div class="col-auto text-neutral-05">{{ config.Name }}:</div>
              <div class="col-auto text-neutral-07 fw-semibold">
                {{ config.Value }}
              </div>
            </div>
            <div class="row align-items-center mt-2 order-info-box me-0 ms-0">
              <div class="col text-neutral-05 text-uppercase">total</div>
              <div class="col-auto text-neutral-07">{{ product.Price }} lei</div>
            </div>
            <div class="row align-items-center mt-2 order-info-box me-0 ms-0">
              <div class="col text-neutral-05 text-uppercase">Pct. Fid.</div>
              <div class="col-auto text-neutral-07">
                {{ product.FidelityPoints }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <PaginationComponent
  :numberOfPages="order.Products.PageDetails.TotalPageCount"
  :pageNumber="filter.PageNumber"
  :functionName="SelectPage"
></PaginationComponent>
</template>

<script>
import PaginationComponent from '../General/PaginationComponent.vue';

export default {
  name: 'OrderDetails',
  emits: ['back', 'cancel-order', 'get'],
  props: {
    order: Object,
    filter: Object,
  },
  components: {
    PaginationComponent,
  },
  methods: {
    CancelOrder(orderId) {
      this.$emit('cancel-order', orderId);
    },
    ShowTranslatedStatus(status) {
      switch (status) {
        case 'New':
          return 'Nou';
        case 'In progress':
          return 'În progres';
        case 'Ready':
          return 'Pregătită';
        case 'Finished':
          return 'Preluată';
        case 'Not Picket UP':
          return 'Neridicată';
        case 'Canceled':
          return 'Anulată';
        default:
          return '';
      }
    },
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr;
      this.$emit('get', this.order.Id);
    },
  },
};
</script>

<style scoped>
.order.card .order-info-box {
  padding: 8px 4px;
  background-color: #f4f7f9;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  border-radius: 8px;
}

.order.card {
  border: 0;
  border-radius: 12px;
  box-shadow: 0px 3px 5px 0px #0000001a;
}

.order.card .card-body {
  padding: 16px;
}

.logo {
  width: 70px;
  height: 70px;
  border-radius: 12px;
  object-fit: cover;
}

hr {
  color: var(--neutral-4) !important;
}

.total-fp-text {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-align: left;
}

.border-bottom {
  border-color: var(--blacktint-1) !important;
}

.coupon-border {
  border: solid 1px #c3cccf;
  border-radius: 10px;
  padding: 6px 12px;
}
</style>

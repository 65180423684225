<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="row align-items-center ms-0 me-0 ms-lg-3 me-lg-3"
    style="padding: 29px 24px 14px 24px"
  >
    <div class="col heading-05 text-neutral-06">Recenziile mele</div>
    <div class="col-md-7 col-lg-5 col-xxl-3 mt-3 mt-md-0">
      <div class="input-group left">
        <span class="input-group-text">
          <img src="../../assets/images/icons/search-normal-20x20.svg" alt="..."/>
        </span>
        <input
          v-model="filter.SearchQuery"
          @keyup.enter="$emit('get')"
          type="text"
          placeholder="Caută o recenzie..."
          class="form-control"
        />
      </div>
    </div>
  </div>
  <table class="custom table d-none d-xl-table">
    <thead>
      <tr>
        <th>Review</th>
        <th :class="{ active: filter.OrderBy.includes('rating') }" width="150px">
          <span class="pointer" @click="OrderBy('rating')">
            Rating
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="rating" class="ms-1" />
          </span>
        </th>
        <th :class="{ active: filter.OrderBy.includes('date') }" width="150px">
          <span class="pointer" @click="OrderBy('date')">
            Data
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="date" class="ms-1" />
          </span>
        </th>
        <th width="320px">Produs</th>
        <th>Status</th>
        <th width="150px"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(review, index) in reviews" :key="index" valign="middle">
        <td>
          <div v-if="review.OrderId" class="text-button-03 text-neutral-08">
            #{{ review.OrderId }}
          </div>
          <div v-else class="text-button-03 text-danger-05">Nespecificat</div>
          <div class="mt-1 paragraph-03 text-neutral-07">{{ review.Comment }}</div>
        </td>
        <td>
          <div class="row">
            <RatingComponent :stars="review.Rating" />
          </div>
        </td>
        <td>
          <span class="caption-02 text-neutral-07">{{ review.Date }}</span>
        </td>
        <td>
          <div class="row align-items-center">
            <div class="col-auto">
              <img :src="$utils.ShowProductImage(review.ImgBase64)" class="cookie-img" />
            </div>
            <div class="col paragraph-03 text-neutral-08">
              {{ review.ProductName }}
            </div>
          </div>
        </td>
        <td>
          <div class="review-aproved" v-if="review.IsAproved">Aprobat</div>
          <div class="review-pending" v-else>În așteptare</div>
        </td>
        <td>
          <div class="row table-actions-btn justify-content-end">
            <div class="col-auto pe-0">
              <button type="button" class="button" @click="$emit('edit', review.Id)">
                <img src="../../assets/images/icons/table-edit-icon.svg" alt="..."/>
              </button>
            </div>
            <div class="col-auto">
              <button type="button" class="button" @click="$emit('delete', review.Id)">
                <img src="../../assets/images/icons/table-delete-icon.svg" alt="..."/>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="mt-3 d-xl-none me-3 ms-3">
    <div class="ps-3 pe-3 mb-4">
      <div class="text-button-02 text-neutral-07">Filtre</div>
      <div class="dropdown filter-border" :class="{ 'value-selected': filter.OrderBy }">
        <button
          class="btn-dropdown-outlined w-100 dropdown-toggle"
          type="button"
          id="orderByDropdownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span v-if="filter.OrderBy"> Sortare: {{ selectedOrderBy }} </span>
          <span v-else> Toate </span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="orderByDropdownMenu">
          <li
            @click="
              filter.OrderBy = item.Value;
              selectedOrderBy = item.Label;
              SelectPage(1);
            "
            v-for="(item, index) in orderList"
            :key="index"
          >
            <span
              class="dropdown-item d-flex align-items-center"
              :class="{ active: filter.OrderBy == item.Value }"
            >
              {{ item.Label }}
            </span>
            <hr
              class="dropdown-divider me-3 ms-3 border-color-blacktint-1"
              v-if="index < orderList.length - 1"
            />
          </li>
        </ul>
        <span
          class="btn-remove"
          v-on:click="
            filter.OrderBy = '';
            selectedOrderBy = '';
            SelectPage(1);
          "
        >
        </span>
      </div>
    </div>
    <div class="row me-0 ms-0">
      <div class="col-md-6 col-lg-4 mb-3" v-for="(review, index) in reviews" :key="index">
        <div class="review card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <img :src="$utils.ShowProductImage(review.ImgBase64)" class="cookie-img" alt="..."/>
              </div>
              <div class="col paragraph-03 text-neutral-08">
                {{ review.ProductName }}
              </div>
            </div>
            <div class="mt-1 paragraph-03 text-neutral-07">{{ review.Comment }}</div>
            <div class="row align-items-center mt-2 review-info-box me-0 ms-0">
              <div class="col text-neutral-05 text-uppercase">ID Comandă</div>
              <div class="col-auto">
                <span class="paragraph-01 fw-semibold text-neutral-07" v-if="review.OrderId">
                  #{{ review.OrderId }}</span
                >
                <span v-else class="text-button-03 text-danger-05">Nespecificat</span>
              </div>
            </div>
            <div class="row align-items-center mt-2 review-info-box me-0 ms-0">
              <div class="col text-neutral-05 text-uppercase">Rating</div>
              <div class="col-auto">
                <div class="row">
                  <RatingComponent :stars="review.Rating" />
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-2 review-info-box me-0 ms-0">
              <div class="col text-neutral-05 text-uppercase">Data</div>
              <div class="col-auto text-neutral-07">
                {{ review.Date }}
              </div>
            </div>
            <div class="row align-items-center justify-content-between mt-3">
              <div class="col-auto">
                <button
                  type="button"
                  class="button text-button-02 fw-semibold btn-primary-outlined pt-2 pb-2 pe-3 ps-3 pe-sm-2 ps-sm-2"
                  @click="$emit('edit', review.Id)"
                >
                  Editează
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8 3.50002L4.95829 10.7417C4.69996 11.0167 4.44996 11.5584 4.39996 11.9334L4.09162 14.6334C3.98329 15.6084 4.68329 16.275 5.64996 16.1084L8.33329 15.65C8.70829 15.5834 9.23329 15.3084 9.49162 15.025L16.3333 7.78335C17.5166 6.53335 18.05 5.10835 16.2083 3.36668C14.375 1.64168 12.9833 2.25002 11.8 3.50002Z"
                      stroke="#D6366C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6582 4.70825C11.0165 7.00825 12.8832 8.76659 15.1999 8.99992"
                      stroke="#D6366C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.25 18.8333H18.25"
                      stroke="#D6366C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  class="button text-button-02 fw-semibold text-neutral-05 pt-2 pb-2"
                  @click="$emit('delete', review.Id)"
                >
                  Șterge
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.75 5.48332C14.975 5.20832 12.1833 5.06665 9.4 5.06665C7.75 5.06665 6.1 5.14998 4.45 5.31665L2.75 5.48332"
                      stroke="#688088"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.3335 4.64175L7.51683 3.55008C7.65016 2.75841 7.75016 2.16675 9.1585 2.16675H11.3418C12.7502 2.16675 12.8585 2.79175 12.9835 3.55841L13.1668 4.64175"
                      stroke="#688088"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.9582 8.1167L15.4165 16.5084C15.3248 17.8167 15.2498 18.8334 12.9248 18.8334H7.57484C5.24984 18.8334 5.17484 17.8167 5.08317 16.5084L4.5415 8.1167"
                      stroke="#688088"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.8584 14.25H11.6334"
                      stroke="#688088"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.1665 10.9167H12.3332"
                      stroke="#688088"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="reviews && reviews.length == 0" class="mt-5 mb-5">
    <not-found-component text="Nu există recenzii" />
  </div>
  <pagination-component
    :numberOfPages="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    :functionName="SelectPage"
  ></pagination-component>
</template>

<script>
import RatingComponent from '../General/RatingComponent.vue';
import PaginationComponent from '../General/PaginationComponent.vue';
import OrderByIconComponent from '../General/OrderByIconComponent.vue';
import NotFoundComponent from '../General/NotFoundComponent.vue';

export default {
  name: 'UserReviews',
  emits: ['get', 'delete', 'edit'],
  props: {
    reviews: Array,
    filter: Object,
    pagination: Object,
  },
  components: {
    RatingComponent,
    PaginationComponent,
    OrderByIconComponent,
    NotFoundComponent,
  },
  data() {
    return {
      selectedOrderBy: '',
      orderList: [
        {
          Value: 'rating',
          Label: 'Rating crescător',
        },
        {
          Value: 'date',
          Label: 'Data crescătoare',
        },
        {
          Value: 'rating_desc',
          Label: 'Rating descrescător',
        },
        {
          Value: 'date_desc',
          Label: 'Data descrescătoare',
        },
      ],
    };
  },
  methods: {
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = orderBy;
      }
      this.SelectPage(1);
    },
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr;
      this.$emit('get');
    },
  },
};
</script>

<style scoped>
.table-actions-btn button {
  border: 1px solid transparent;
}
.table-actions-btn button:hover {
  background-color: var(--neutral-2);
}

.cookie-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.review.card .review-info-box {
  padding: 8px 4px;
  background-color: #f4f7f9;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  border-radius: 8px;
}
.review.card {
  border: 0;
  border-radius: 12px;
  box-shadow: 0px 3px 5px 0px #0000001a;
}
.review.card .card-body {
  padding: 16px;
}

.review-aproved {
  color: var(--secondary-accents-success-06, #509920);
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
}

.review-pending {
  color: var(--secondary-accents-info-06, #048E9D);
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
}
</style>

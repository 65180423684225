<!-- eslint-disable max-len -->
<template>
  <user-profile-mobile-navbar-component
    :selectedTab="selectedTab"
    :sidebarList="sidebarList"
    @select-tab="SelectTab"
  />
  <div class="position-relative mt-5 pt-3 mb-5 pb-sm-3 pb-5 mt-mobile">
    <div class="ms-4 d-lg-none" style="margin-bottom: 42px">
      <div class="heading-06 text-neutral-07" v-if="currentUser">
        {{ currentUser.LastName }} {{ currentUser.FirstName }}
      </div>
      <div class="mt-2">
        <span class="heading-05 text-primary-05 fw-normal">{{ user.FidelityPoints }}</span>
        <span class="sub-heading-04 text-neutral-08 ms-2">puncte de fidelitate</span>
      </div>
    </div>
    <div class="sidebar d-none d-sm-block">
      <div class="ms-4 d-none d-lg-block" style="margin-bottom: 42px">
        <div class="heading-06 text-neutral-07" v-if="currentUser">
          {{ currentUser.LastName }} {{ currentUser.FirstName }}
        </div>
        <div class="mt-2">
          <span class="heading-05 text-primary-05 fw-normal">{{ user.FidelityPoints }}</span>
          <span class="sub-heading-04 text-neutral-08 ms-2">puncte de fidelitate</span>
        </div>
      </div>
      <nav class="nav nav-pills nav-justified flex-column">
        <a
          :class="{ active: side.Long === selectedTab }"
          class="nav-link"
          aria-current="page"
          href="#"
          v-for="(side, index) in sidebarList"
          :key="index"
          @click="SelectTab(side.Long)"
        >
          <div class="d-none d-lg-block">
            {{ side.Long }}
          </div>
          <div class="d-lg-none text-center">
            <img :src="require(`@/assets/images/icons/${side.Icon}`)" alt="..." />
            <div>
              {{ side.Short }}
            </div>
          </div>
        </a>
      </nav>
    </div>
    <div class="container-custom">
      <div class="card" v-if="selectedTab === 'Istoric comenzi'">
        <div v-if="Object.keys(orderDetails).length == 0">
          <UserOrderHistoryComponent
            :user="user"
            :orders="historyOrders"
            :pagination="historyPagination"
            :filter="historyFiler"
            @get="GetUserOrders"
            @getOrder="GetUserOrderDetails"
          />
        </div>
        <div v-else>
          <UserOrderDetailsComponent
            :order="orderDetails"
            :filter="orderDetailsFilter"
            @back="GetUserOrders('Istoric comenzi')"
            @cancel-order="CancelOrder"
            @get="GetUserOrderDetails"
          />
        </div>
      </div>
      <div class="card" v-if="selectedTab === 'Recenziile mele'">
        <UserReviewsComponent
          :reviews="reviews"
          :filter="reviewsFilter"
          :pagination="reviewsPagination"
          @get="GetUserReviews"
          @delete="DeleteReview"
          @edit="GetReview"
        />
      </div>
      <div v-if="selectedTab === 'Wishlist'" class="card">
        <UserWishlistComponent
          :products="wishlist"
          :filter="wishlistFilter"
          :pagination="wishlistPagination"
          @get="GetUserWishlist"
          @delete="RemoveFromWishlist"
        />
      </div>
      <div v-if="selectedTab === 'Setări'" class="card">
        <UserSettingsComponent :user="userSettings" @edit="EditUser" @delete="DeleteUserAccount" />
      </div>
    </div>
  </div>

  <!-- <div class="mobile-menu d-sm-none">
    <nav class="nav nav-pills nav-justified">
      <a
        :class="{ active: side.Long === selectedTab }"
        class="nav-link"
        aria-current="page"
        href="#"
        v-for="(side, index) in sidebarList"
        :key="index"
        @click="SelectTab(side.Long)"
      >
        <div class="d-none d-lg-block">
          {{ side.Long }}
        </div>
        <div class="d-lg-none text-center">
          <img :src="require(`@/assets/images/icons/${side.Icon}`)" alt="..." />
          <div>
            {{ side.Short }}
          </div>
        </div>
      </a>
    </nav>
  </div> -->

  <img
    :src="require('@/assets/images/user-profile-right-cookies.webp')"
    class="img-cookie-right"
    alt="..."
  />
  <EditReviewModal
    ref="editReviewModalRef"
    :review="selectedReview"
    :productId="selectedReview.ProductId"
    @edit="EditReview"
  />
</template>

<script>
import UserOrderHistoryComponent from '../../components/User/UserOrderHistoryComponent.vue';
import UserOrderDetailsComponent from '../../components/User/UserOrderDetailsComponent.vue';
import UserReviewsComponent from '../../components/User/UserReviewsComponent.vue';
import UserWishlistComponent from '../../components/User/UserWishlistComponent.vue';
import UserSettingsComponent from '../../components/User/UserSettingsComponent.vue';
import EditReviewModal from '../../components/Product/Modals/EditReviewModalComponent.vue';
import UserProfileMobileNavbarComponent from '../../components/General/UserProfileMobileNavbarComponent.vue';

export default {
  name: 'UserProfile',
  data() {
    return {
      user: {},
      userSettings: {},
      dashboardOrders: [],
      dashboardPagination: {},
      dashboardFilter: {
        PageNumber: 1,
        PageSize: 3,
      },
      historyOrders: [],
      historyPagination: {},
      historyFiler: {
        PageNumber: 1,
        PageSize: 10,
        SearchQuery: '',
        OrderBy: 'date_desc',
        FetchData: false,
      },
      orderDetails: {},
      orderDetailsFilter: {
        PageNumber: 1,
        PageSize: 3,
      },
      selectedReview: {},
      reviews: [],
      reviewsPagination: {},
      reviewsFilter: {
        PageNumber: 1,
        PageSize: 5,
        OrderId: '',
        OrderBy: '',
        SearchQuery: '',
      },
      wishlist: [],
      wishlistPagination: {},
      wishlistFilter: {
        PageNumber: 1,
        PageSize: 4,
        SearchQuery: '',
        Category: '',
        Subcategory: '',
      },
      sidebarList: [
        {
          Icon: 'order-box-userprofile.svg',
          Long: 'Istoric comenzi',
          Short: 'Comenzi',
        },
        {
          Icon: 'reviews-star-userprofile.svg',
          Long: 'Recenziile mele',
          Short: 'Recenzii',
        },
        {
          Icon: 'wishlist-heart-userprofile.svg',
          Long: 'Wishlist',
          Short: 'Wishlist',
        },
        {
          Icon: 'settings-cog-userprofile.svg',
          Long: 'Setări',
          Short: 'Setări',
        },
      ],
      selectedTab: 'Istoric comenzi',
    };
  },
  components: {
    UserOrderHistoryComponent,
    UserOrderDetailsComponent,
    UserReviewsComponent,
    UserWishlistComponent,
    UserSettingsComponent,
    EditReviewModal,
    UserProfileMobileNavbarComponent,
  },
  methods: {
    CancelOrder(orderId) {
      this.$swal
        .fire({
          title: 'Anulare comandă',
          text: 'Sunteți sigur că doriți să vă anulați comanda? Anularea comenzii este o acțiune ireversibilă!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Anulează',
          cancelButtonText: 'Renunță',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            this.$axios
              .put(`/api/Order/cancelOrder/${orderId}`)
              .then(() => {
                this.GetUserOrders('Istoric comenzi');
                this.$store.state.loader = false;
                this.$utils.ToastNotify('success', 'Comanda a fost anulată.');
              })
              .catch(() => {
                this.$store.state.loader = false;
                this.$utils.ToastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    SelectTab(tab) {
      switch (tab) {
        case 'Dashboard':
          this.GetDashboadOrders(tab);
          break;
        case 'Istoric comenzi':
          this.GetUserOrders(tab);
          break;
        case 'Recenziile mele':
          this.GetUserReviews(tab);
          break;
        case 'Wishlist':
          this.GetUserWishlist(tab);
          break;
        case 'Setări':
          this.GetUserSettings(tab);
          break;
        case 'Deconectare':
          this.LogOut();
          break;
        default:
          break;
      }
    },
    LogOut() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'HomePage' });
      });
    },
    DeleteUserAccount(userId) {
      this.$swal
        .fire({
          title: 'Stergere cont',
          text: 'Sunteți sigur că doriți să vă ștergeți contul? Ștergerea contul este o acțiune ireversibilă!',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Șterge contul!',
          cancelButtonText: 'Renunță',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            this.$axios
              .delete(`/api/User/delete/${userId}`)
              .then(() => {
                this.$store.dispatch('auth/logout').then(() => {
                  this.$router.push({ name: 'HomePage' });
                });
                this.$store.state.loader = false;
              })
              .catch(() => {
                this.$store.state.loader = false;
                this.$utils.ToastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    EditUser(user) {
      this.$store.state.loader = true;
      const editUser = {
        UserName: user.UserName,
        Email: user.Email,
        PhoneNumber: user.PhoneNumber ? user.PhoneNumber : '',
        // KeepBillingAddress: user.KeepBillingAddress ? user.KeepBillingAddress : false,
        AllowNewsLetter: user.AllowNewsLetter ? user.AllowNewsLetter : false,
        ShippingAddress: user.Delivery,
        BillingAddress: user.Billing,
      };
      this.$axios
        .put(`/api/Auth/update/${user.Id}`, editUser)
        .then(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('success', 'Informațiile au fost modificate.');
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    async GetUserDetails() {
      this.$store.state.loader = true;
      await this.$axios
        .get('/api/User/getDetails')
        .then((response) => {
          this.user = response.data;
          if (this.user.Location === null) {
            this.user.Location = {};
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetUserSettings(tab) {
      this.$store.state.loader = true;
      this.$axios
        .get('/api/User/getUserInformations')
        .then((response) => {
          this.userSettings = response.data;
          if (this.userSettings.Delivery == null) {
            this.userSettings.Delivery = {
              Id: '',
              Title: '',
              Address: '',
              City: '',
              State: '',
              Country: '',
              PostalCode: '',
            };
          }
          if (this.userSettings.Billing == null) {
            this.userSettings.Billing = {
              Id: '',
              Title: '',
              Address: '',
              City: '',
              State: '',
              Country: '',
              PostalCode: '',
            };
          }
          if (tab) {
            this.selectedTab = tab;
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetDashboadOrders(tab) {
      this.$store.state.loader = true;
      const searchParams = new URLSearchParams(this.dashboardFilter);
      this.$axios
        .get(`/api/Order/userRecentOrders?${searchParams}`)
        .then((response) => {
          this.dashboardOrders = response.data.Items;
          this.dashboardPagination = response.data.PageDetails;
          this.filter.FetchData = true;
          if (tab) {
            this.selectedTab = tab;
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetUserOrders(tab) {
      this.$store.state.loader = true;
      const searchParams = new URLSearchParams(this.historyFiler);
      this.$axios
        .get(`/api/Order/userOrders?${searchParams}`)
        .then((response) => {
          this.historyOrders = response.data.Items;
          this.historyPagination = response.data.PageDetails;
          this.orderDetails = {};
          if (tab) {
            this.selectedTab = tab;
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetUserOrderDetails(id) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.orderDetailsFilter.PageNumber,
        PageSize: this.orderDetailsFilter.PageSize,
        OrderId: id,
      };
      this.$axios
        .get(
          // eslint-disable-next-line comma-dangle
          `/api/Order/getUserOrder?${new URLSearchParams(searchParams)}`
        )
        .then((response) => {
          this.orderDetails = response.data;
          // console.log(this.orderDetails);
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetUserReviews(tab) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.reviewsFilter.PageNumber,
        PageSize: this.reviewsFilter.PageSize,
        ...(this.reviewsFilter.OrderId ? { OrderId: this.reviewsFilter.OrderId } : ''),
        ...(this.reviewsFilter.OrderBy ? { OrderBy: this.reviewsFilter.OrderBy } : ''),
        ...(this.reviewsFilter.SearchQuery ? { SearchQuery: this.reviewsFilter.SearchQuery } : ''),
      };
      this.$axios
        .get(
          `/api/Review/getUserReviews?${new URLSearchParams(
            // eslint-disable-next-line comma-dangle
            searchParams
            // eslint-disable-next-line comma-dangle
          )}`
        )
        .then((response) => {
          this.reviews = response.data.Items;
          this.reviewsPagination = response.data.PageDetails;
          if (tab) {
            this.selectedTab = tab;
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetReview(id) {
      this.$store.state.loader = true;
      this.$axios
        .get(`/api/Review/get/${id}`)
        .then((response) => {
          this.selectedReview = response.data;
          // this.$refs.editReviewModalRef.ClearModal();
          this.$store.state.loader = false;
          // eslint-disable-next-line no-undef
          $('#editReviewModal').modal('show');
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    EditReview(review) {
      this.$store.state.loader = true;
      const editReview = {
        Comment: review.Comment,
        Rating: review.Rating,
        OrderId: review.OrderId,
      };
      this.$axios
        .put(`/api/Review/edit/${review.Id}`, editReview)
        .then(() => {
          // eslint-disable-next-line no-undef
          $('#editReviewModal').modal('hide');
          this.GetUserReviews();
          this.$store.state.loader = false;
          this.$utils.ToastNotify('success', 'Recenzia a fost modificată.');
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    DeleteReview(id) {
      this.$swal
        .fire({
          title: 'Avertisment',
          text: 'Sigur doriți să ștergeți această recenzie?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sterge recenzia',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            this.$axios
              .delete(`/api/Review/delete/${id}?$`)
              .then(() => {
                this.reviewsFilter.PageNumber = 1;
                this.GetUserReviews();
                this.$store.state.loader = false;
                this.$utils.ToastNotify('success', 'Recenzia a fost ștearsă.');
              })
              .catch(() => {
                this.$store.state.loader = false;
                this.$utils.ToastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    GetUserWishlist(tab) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.wishlistFilter.PageNumber,
        PageSize: this.wishlistFilter.PageSize,
        ...(this.wishlistFilter.Category ? { CategoryId: this.wishlistFilter.Category.Id } : ''),
        ...(this.wishlistFilter.SearchQuery
          ? { SearchQuery: this.wishlistFilter.SearchQuery }
          : ''),
        ...(this.wishlistFilter.Subcategory
          ? { SubcategoryId: this.wishlistFilter.Subcategory.Id }
          : ''),
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
      };
      this.$axios
        .get(
          // eslint-disable-next-line comma-dangle
          `/api/User/getWishlist?${new URLSearchParams(searchParams)}`
        )
        .then((response) => {
          this.wishlist = response.data.Items;
          this.wishlist = this.wishlist.map((item) => ({ ...item, Amount: 1 }));
          this.wishlistPagination = response.data.PageDetails;
          if (tab) {
            this.selectedTab = tab;
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },

    RemoveFromWishlist(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            const searchParams = {
              ProductId: id,
            };
            this.$axios
              .delete(`/api/User/removeFromFavorite?${new URLSearchParams(searchParams)}`)
              .then(() => {
                this.wishlistFilter.PageNumber = 1;
                this.GetUserWishlist();
                this.$store.state.loader = false;
                this.$utils.ToastNotify('success', 'Produsul a fost șters de la favorite.');
              })
              .catch(() => {
                this.$store.state.loader = false;
                this.$utils.ToastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    // eslint-disable-next-line no-unused-expressions
    this.currentUser;
    await this.GetUserDetails();
    this.SelectTab('Istoric comenzi');
  },
};
</script>

<style scoped>
.card {
  border-color: transparent;
  box-shadow: 0px 5px 60px 0px #0000001a;
  border-radius: 16px;
}

.sidebar .nav-link:hover:not(.active) {
  width: 16vw /*13vw*/;
}
.sidebar .nav-link:not(:last-child) {
  margin-bottom: 8px;
}
.sidebar .nav-link.active img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(3deg) brightness(97%) contrast(111%);
}
.sidebar .nav-link.active {
  box-shadow: 0px 5px 15px -5px #d6366c80;
  background-color: var(--primary-5);
  color: white;
  width: 16vw /*13vw*/;
  font-weight: 500;
}

.sidebar .nav-link img {
  filter: invert(55%) sepia(11%) saturate(621%) hue-rotate(149deg) brightness(86%) contrast(90%);
}
.sidebar .nav-link {
  width: 14vw /*11vw*/;
  border-width: 1px 1px 1px 0px;
  border-color: var(--neutral-2);
  border-style: solid;
  border-radius: 0px 12px 12px 0px;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--neutral-5);
  transition: 0.2s;
}
.sidebar {
  height: 540px;
  width: 15vw /*294px*/;
  position: fixed;
  left: 0;
}
@media only screen and (min-width: 576px) {
  .container-custom {
    margin-left: 19vw;
  }
}
@media only screen and (min-width: 768px) {
  .sidebar .nav-link {
    padding: 19px 24px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1200px) {
  .sidebar .nav-link {
    padding-left: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .container-custom {
    max-width: 78vw;
  }
}
@media only screen and (min-width: 1400px) {
  .container-custom {
    max-width: 75vw;
  }
  .sidebar .nav-link:hover:not(.active) {
    width: 248px /*13vw*/;
    padding-left: 48px;
  }
  .sidebar .nav-link.active {
    width: 248px /*13vw*/;
  }
  .sidebar .nav-link {
    width: 224px /*11vw*/;
  }
  .sidebar {
    width: 294px /*15vw*/;
  }
}
@media only screen and (min-width: 1600px) {
  .container-custom {
    max-width: 70vw;
  }
}

.img-cookie-right {
  position: fixed;
  object-fit: cover;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
/*-------------------------------------------------------------------*/
</style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="row align-items-center ms-0 me-0 ms-lg-3 me-lg-3"
    style="padding: 29px 24px 14px 24px"
  >
    <div class="col heading-05 text-neutral-06">Istoric comenzi</div>
    <div class="col-md-6 col-lg-5 col-xxl-3 mt-3 mt-md-0">
      <div class="input-group left">
        <span class="input-group-text">
          <img src="../../assets/images/icons/search-normal-20x20.svg" alt="..." />
        </span>
        <input
          v-model="filter.SearchQuery"
          @keyup.enter="$emit('get')"
          type="text"
          placeholder="Caută o comandă după ID..."
          class="form-control"
        />
      </div>
    </div>
  </div>
  <table class="custom table d-none d-xl-table">
    <thead>
      <tr>
        <th :class="{ active: filter.OrderBy.includes('id') }">
          <span class="pointer" @click="OrderBy('id')">
            ID
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="id" class="ms-1" />
          </span>
        </th>
        <th>Comenzi</th>
        <th :class="{ active: filter.OrderBy.includes('quantity') }">
          <span class="pointer" @click="OrderBy('quantity')">
            Cantitate
            <order-by-icon-component
              :orderBy="filter.OrderBy"
              orderByName="quantity"
              class="ms-1"
            />
          </span>
        </th>
        <th :class="{ active: filter.OrderBy.includes('price') }">
          <span class="pointer" @click="OrderBy('price')">
            Preț total
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="price" class="ms-1" />
          </span>
        </th>
        <th :class="{ active: filter.OrderBy.includes('fidelity_points') }">
          <span class="pointer" @click="OrderBy('fidelity_points')">
            Pct. Fidelitate
            <order-by-icon-component
              :orderBy="filter.OrderBy"
              orderByName="fidelity_points"
              class="ms-1"
            />
          </span>
        </th>
        <th>Status</th>
        <th :class="{ active: filter.OrderBy.includes('date') }">
          <span class="pointer" @click="OrderBy('date')">
            Data
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="date" class="ms-1" />
          </span>
        </th>
        <th :class="{ active: filter.OrderBy.includes('pick_up_date') }">
          <span class="pointer" @click="OrderBy('pick_up_date')">
            Data ridicării
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="pick_up_date" class="ms-1" />
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="pointer"
        @click="$emit('getOrder', order.Id)"
        v-for="(order, index) in orders"
        :key="index"
        valign="middle"
      >
        <td>#{{ order.Id }}</td>
        <td>
          <div class="row align-items-center">
            <div class="col-auto" v-if="order.NumberOfProducts > 1">
              <img src="../../assets/images/icons/multiple-products-24x-24.svg" alt="..." />
            </div>
            <div
              class="col text-button-02 text-neutral-07"
              :class="{ 'ps-0': order.NumberOfProducts > 1 }"
            >
              {{ order.ProductName }}
            </div>
          </div>
        </td>
        <td>{{ order.Quantity }}</td>
        <td>{{ order.TotalPrice }} lei</td>
        <td>{{ order.FidelityPoints }}</td>
        <td>
          <span v-if="order.Status === 'Canceled'" class="text-button-02 text-danger-05">
            {{ ShowTranslatedStatus(order.Status) }}
          </span>
          <span v-else class="text-button-02 text-info-06">
            {{ ShowTranslatedStatus(order.Status) }}
          </span>
        </td>
        <td>{{ order.Date }}</td>
        <td>{{ order.PickUpDate }}</td>
      </tr>
    </tbody>
  </table>
  <!--mobile-->
  <div class="row d-xl-none me-3 ms-3 mt-3">
    <div
      class="col-md-6 col-lg-4 mb-3"
      @click="$emit('getOrder', order.Id)"
      v-for="(order, index) in orders"
      :key="index"
    >
      <div class="order card">
        <div class="card-body">
          <div class="row text-button-01 text-neutral-07 align-items-center">
            <div class="col-4 pe-0">ID: #{{ order.Id }}</div>
            <div class="col ps-1 pe-1 text-center">
              <div v-if="order.Status === 'Canceled'" class="badge bg-danger-05">
                {{ ShowTranslatedStatus(order.Status) }}
              </div>
              <div v-else  class="badge bg-info-06">
                {{ ShowTranslatedStatus(order.Status) }}
              </div>
            </div>
            <div class="col ps-0 text-end">
              {{ order.Date }}
            </div>

          </div>
          <div class="row align-items-center mt-2" style="height: 25px">
            <div class="col-auto" v-if="order.NumberOfProducts > 1">
              <img src="../../assets/images/icons/multiple-products-24x-24.svg" alt="..." />
            </div>
            <div
              class="col paragraph-03 text-neutral-07"
              :class="{ 'ps-0': order.NumberOfProducts > 1 }"
            >
              {{ order.ProductName }}
            </div>
          </div>
          <div class="row align-items-center mt-2 order-info-box me-0 ms-0">
            <div class="col text-neutral-05 text-uppercase">Cantitate</div>
            <div class="col-auto text-neutral-07">
              {{ order.Quantity }}
            </div>
          </div>
          <div class="row align-items-center mt-2 order-info-box me-0 ms-0">
            <div class="col text-neutral-05 text-uppercase">Preț</div>
            <div class="col-auto text-neutral-07">{{ order.TotalPrice }} lei</div>
          </div>
          <div class="row align-items-center mt-2 order-info-box me-0 ms-0">
            <div class="col text-neutral-05 text-uppercase">Data ridicării:</div>
            <div class="col-auto text-neutral-07">
              {{ order.PickUpDate }}
            </div>
          </div>
          <div class="row align-items-center mt-2 order-info-box me-0 ms-0">
            <div class="col text-neutral-05 text-uppercase">Pct. Fid.</div>
            <div class="col-auto text-neutral-07">
              {{ order.FidelityPoints }}
            </div>
          </div>
          <button
            type="button"
            class="button btn-primary-outlined pt-2 pb-2 mt-3 w-100"
            @click="$emit('getOrder', order.Id)"
          >
            Vezi comanda
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--end-->
  <!-- <div v-if="orders && orders.length == 0 && (filter.SearchQuery || filter.FetchData)" class="mt-5 mb-5">
    <not-found-component text="Nu există un istoric al comenzilor" />
  </div> -->
    <div v-if="orders && orders.length == 0" class="mt-5 mb-5">
    <not-found-component text="Nu există un istoric al comenzilor" />
  </div>
  <!-- <Pagination
    v-if="pagination.TotalPageCount > 1"
    class="mt-5 pt-5"
    :pageSize="pagination.PageSize"
    :totalItemCount="pagination.TotalItemCount"
    :totalPageCount="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    @select-page="SelectPage"
  /> -->
  <pagination-component
    :numberOfPages="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    :functionName="SelectPage"
  ></pagination-component>
</template>

<script>
import PaginationComponent from '../General/PaginationComponent.vue';
import OrderByIconComponent from '../General/OrderByIconComponent.vue';
import NotFoundComponent from '../General/NotFoundComponent.vue';

export default {
  name: 'UserOrderHistory',
  emits: ['get', 'getOrder'],
  components: {
    PaginationComponent,
    OrderByIconComponent,
    NotFoundComponent,
  },
  props: {
    user: Object,
    orders: Array,
    filter: Object,
    pagination: Object,
  },
  methods: {
    ShowTranslatedStatus(status) {
      switch (status) {
        case 'New':
          return 'Nou';
        case 'In progress':
          return 'În progres';
        case 'Ready':
          return 'Pregătită';
        case 'Finished':
          return 'Preluată';
        case 'Not Picket UP':
          return 'Neridicată';
        case 'Canceled':
          return 'Anulată';
        default:
          return '';
      }
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = orderBy;
      }
      this.$emit('get');
    },
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr;
      this.$emit('get');
    },
  },
};
</script>

<style scoped>
.order.card .order-info-box {
  padding: 8px 4px;
  background-color: #f4f7f9;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  border-radius: 8px;
}
.order.card {
  border: 0;
  border-radius: 12px;
  box-shadow: 0px 3px 5px 0px #0000001a;
}
.order.card .card-body {
  padding: 16px;
}
</style>
